<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.005 512.005"
    style="enable-background:new 0 0 512.005 512.005;"
    xml:space="preserve"
  >
    <path
      style="fill:#FFDC64;"
      d="M370.368,38.236c-1.578-11.686-7.604-22.056-16.975-29.204
	c-19.345-14.759-47.105-11.026-61.864,8.328c-11.242,14.74-26.203,57.028-35.527,85.582c-9.323-28.554-24.284-70.842-35.527-85.582
	c-14.759-19.35-42.509-23.105-61.864-8.328c-9.371,7.147-15.397,17.518-16.975,29.204s1.492,23.286,8.647,32.656
	c16.699,21.902,92.813,64.45,101.443,69.23c1.336,0.742,2.811,1.108,4.276,1.108c1.462,0,2.865-0.481,4.171-1.193l0.105,0.085
	c8.629-4.78,84.744-47.329,101.443-69.23C368.876,61.522,371.945,49.922,370.368,38.236z M164.318,60.185
	c-4.285-5.626-6.13-12.582-5.181-19.591c0.94-7.013,4.561-13.237,10.182-17.527c4.785-3.647,10.431-5.414,16.026-5.414
	c7.991,0,15.888,3.591,21.095,10.415c9.621,12.617,24.768,55.631,34.432,85.511C213.328,97.424,173.887,72.738,164.318,60.185z
	 M347.686,60.185c-9.569,12.552-49.019,37.238-76.554,53.394c9.655-29.88,24.803-72.89,34.432-85.511
	c5.207-6.828,13.104-10.415,21.095-10.415c5.595,0,11.242,1.768,16.026,5.414c5.621,4.289,9.242,10.513,10.182,17.527
	C353.816,47.602,351.971,54.56,347.686,60.185z"
    />
    <path
      style="fill:#FF507D;"
      d="M432.559,512H79.445c-9.751,0-17.656-7.904-17.656-17.656V158.886
	c0-9.751,7.904-17.656,17.656-17.656h353.114c9.751,0,17.656,7.904,17.656,17.656v335.458C450.215,504.096,442.31,512,432.559,512z"
    />
    <path
      style="fill:#D23C69;"
      d="M105.928,423.722V141.231H79.445c-9.751,0-17.656,7.904-17.656,17.656v335.458
	c0,9.751,7.904,17.656,17.656,17.656h353.114c9.75,0,17.656-7.905,17.656-17.656H176.551
	C137.548,494.345,105.928,462.726,105.928,423.722z"
    />
    <path
      style="fill:#FFC850;"
      d="M256.002,150.058c-1.112,0-2.225-0.211-3.276-0.629L120.308,96.462
	c-4.526-1.815-6.733-6.949-4.923-11.478c1.811-4.526,6.974-6.72,11.474-4.918l129.142,51.657l129.142-51.657
	c4.491-1.798,9.664,0.392,11.474,4.918c1.811,4.53-0.396,9.664-4.923,11.478l-132.418,52.967
	C258.226,149.848,257.114,150.058,256.002,150.058z"
    />
    <g>
      <polygon
        style="fill:#FF6991;"
        points="61.789,441.378 132.412,512 211.863,512 61.789,361.927 	"
      />
      <polygon
        style="fill:#FF6991;"
        points="61.789,282.476 291.313,512 370.764,512 61.789,203.026 	"
      />
      <path
        style="fill:#FF6991;"
        d="M450.215,432.55L158.895,141.231H79.445l365.599,365.599c3.196-3.196,5.172-7.61,5.172-12.484
		V432.55H450.215z"
      />
      <polygon
        style="fill:#FF6991;"
        points="450.215,273.648 317.797,141.231 238.346,141.231 450.215,353.099 	"
      />
      <path
        style="fill:#FF6991;"
        d="M450.215,158.886c0-9.751-7.905-17.656-17.656-17.656h-35.311l52.967,52.967V158.886z"
      />
    </g>
    <g>
      <polygon
        style="fill:#FF507D;"
        points="273.658,494.345 291.313,512 370.764,512 353.108,494.345 	"
      />
      <polygon
        style="fill:#FF507D;"
        points="105.928,247.165 61.789,203.026 61.789,282.476 105.928,326.616 	"
      />
      <path
        style="fill:#FF507D;"
        d="M176.551,494.345c-39.004,0-70.623-31.619-70.623-70.623v-17.656l-44.139-44.139v79.451L132.412,512
		h79.451l-17.656-17.656H176.551z"
      />
    </g>
    <rect
      x="229.515"
      y="141.235"
      style="fill:#FFDC64;"
      width="52.967"
      height="370.77"
    />
    <rect
      x="229.515"
      y="494.349"
      style="fill:#FFC850;"
      width="52.967"
      height="17.656"
    />
    <g>
      <polygon
        style="fill:#FF507D;"
        points="105.928,141.231 79.445,141.231 105.928,167.714 	"
      />
      <path
        style="fill:#FF507D;"
        d="M445.044,506.829c2.397-2.397,4.107-5.478,4.812-8.926c0.235-1.15,0.359-2.339,0.359-3.559h-17.656
		L445.044,506.829z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
